import {
  SET_VALID_LOUNGE_BY_ID,
  LoungeManagementAction,
  LOUNGE_MANAGEMENT_SET_LOUNGES,
  LOUNGE_MANAGEMENT_SET_MY_LOUNGE,
} from './loungeManagementActionTypes';
import { LoungeManagementState } from './loungesManagementTypes';

const initialState: LoungeManagementState = {
  lounges: [],
};

export const LOUNGE_MANAGEMENT_NAMESPACE = 'LOUNGE_MANAGEMENT_NAMESPACE';

function loungeManagementReducer(
  state = initialState,
  action: LoungeManagementAction
): LoungeManagementState {
  switch (action.type) {
    case LOUNGE_MANAGEMENT_SET_LOUNGES:
      return { ...state, lounges: action.payload, lounge: undefined };
    case LOUNGE_MANAGEMENT_SET_MY_LOUNGE:
      return { ...state, myLounge: action.payload };
    case SET_VALID_LOUNGE_BY_ID:
      return { ...state, lounge: action.payload };
    default:
      return state;
  }
}

export default loungeManagementReducer;
