import { AppAction } from '../types';
import { PropertiesManagementLocation } from './propertyManagementTypes';

export const PROPERTIES_MANAGEMENT_SET_LOCATION_TREE =
  'PROPERTIES_MANAGEMENT_SET_LOCATION_TREE';

export interface PropertiesManagementSetLocationTreeActionType {
  type: typeof PROPERTIES_MANAGEMENT_SET_LOCATION_TREE;
  location: PropertiesManagementLocation;
}

export type PropertiesManagementAction =
  PropertiesManagementSetLocationTreeActionType;

export type PropertiesManagementThunk<T = void> = AppAction<
  PropertiesManagementAction,
  T
>;
