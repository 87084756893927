import { AppAction } from '../types';
import {
  SupportCategory,
  SupportStateSupportImage,
  SupportSubCategory,
} from './SupportTypes';

export const SET_SUPPORT_IMAGE = 'SET_SUPPORT_IMAGE';

export const REMOVE_SUPPORT_IMAGE = 'REMOVE_SUPPORT_IMAGE';
export const SET_SUPPORT_CATEGORIES = 'SET_SUPPORT_CATEGORIES';
export const SET_SUPPORT_SUB_CATEGORIES = 'SET_SUPPORT_SUB_CATEGORIES';

export interface SetSupportCategoriesActionType {
  type: typeof SET_SUPPORT_CATEGORIES;
  payload: SupportCategory[];
}

export interface RemoveSupportImageActionType {
  type: typeof REMOVE_SUPPORT_IMAGE;
  index?: number;
}

export interface SetSupportSubCategoriesActionType {
  type: typeof SET_SUPPORT_SUB_CATEGORIES;
  payload: SupportSubCategory[];
}

export interface setSupportImageActionType {
  type: typeof SET_SUPPORT_IMAGE;
  payload?: SupportStateSupportImage[];
}

export type SupportActions =
  | setSupportImageActionType
  | SetSupportCategoriesActionType
  | SetSupportSubCategoriesActionType
  | RemoveSupportImageActionType;

export type SupportThunkAction<T = void> = AppAction<SupportActions, T>;
