import {
  MyActivitiesAction,
  SET_MY_ACTIVITIES,
  SET_SELECTED_ACTIVITY_PAYMENTS,
} from './myActivitiesActionTypes';
import { MyActivitiesState } from './myActivitiesTypes';

const initialState: MyActivitiesState = {
  myActivities: [],
  selectedActivityPayments: [],
  pagination: {
    eventsTotalResult: 0,
    roomsTotalResult: 0,
    eventsShown: 0,
    roomsShown: 0,
  },
};

export const MY_ACTIVITIES_NAMESPACE = 'MY_ACTIVITIES_NAMESPACE';

function myActivitiesReducer(
  state = initialState,
  action: MyActivitiesAction
): MyActivitiesState {
  switch (action.type) {
    case SET_MY_ACTIVITIES:
      return {
        ...action.payload,
      };
    case SET_SELECTED_ACTIVITY_PAYMENTS:
      return {
        ...state,
        selectedActivityPayments: action.payments,
      };
    //Add case SendMyActivitiesOrders
    default:
      return state;
  }
}

export default myActivitiesReducer;
