import {
  SET_EVENTS,
  EventAction,
  SET_EVENT_BY_ID,
  SET_EVENT_CATEGORIES,
  SET_EVENT_TOTAL_PAGES,
  SET_EVENT_TOTAL_RESULTS,
  SET_EVENT_SUB_CATEGORIES,
} from './eventActionTypes';
import { EventState } from './eventTypes';

const initialState: EventState = {
  events: [],
  myActivityEvents: [],
  eventsCategories: [],
  eventsSubCategories: [],
  totalPages: 0,
  totalResults: 0,
};

export const EVENT_NAMESPACE = 'EVENT_NAMESPACE';

function eventReducer(state = initialState, action: EventAction): EventState {
  switch (action.type) {
    case SET_EVENTS:
      return { ...state, events: action.payload, event: undefined };
    case SET_EVENT_CATEGORIES:
      return { ...state, eventsCategories: action.payload };
    case SET_EVENT_SUB_CATEGORIES:
      return { ...state, eventsSubCategories: action.payload };
    case SET_EVENT_TOTAL_PAGES:
      return { ...state, totalPages: action.payload };
    case SET_EVENT_TOTAL_RESULTS:
      return { ...state, totalResults: action.payload };
    case SET_EVENT_BY_ID:
      return { ...state, event: action.payload };
    default:
      return state;
  }
}

export default eventReducer;
