import { AppAction } from '../types';
import { Credit, CreditInUse } from './creditsTypes';

export const SET_CREDITS = 'SET_CREDITS';
export const SET_CREDIT_IN_USE = 'SET_CREDIT_IN_USE';

export interface SetCreditsActionType {
  type: typeof SET_CREDITS;
  payload: Credit[];
}

export interface SetCreditInUseActionType {
  type: typeof SET_CREDIT_IN_USE;
  payload: CreditInUse;
}

export type CreditsAction = SetCreditsActionType | SetCreditInUseActionType;

export type CreditsThunkAction<T = void> = AppAction<CreditsAction, T>;
