import { AppAction } from '../types';
import { LoungeResource } from './loungesManagementTypes';

export const LOUNGE_MANAGEMENT_SET_MY_LOUNGE =
  'LOUNGE_MANAGEMENT_SET_MY_LOUNGE';
export const SET_VALID_LOUNGE_BY_ID = 'SET_VALID_LOUNGE_BY_ID';
export const LOUNGE_MANAGEMENT_SET_LOUNGES = 'LOUNGE_MANAGEMENT_SET_LOUNGES';

export interface loungeManagementSetLoungesActionType {
  type: typeof LOUNGE_MANAGEMENT_SET_LOUNGES;
  payload: LoungeResource[];
}

export interface setMyLoungeActionType {
  type: typeof LOUNGE_MANAGEMENT_SET_MY_LOUNGE;
  payload: LoungeResource;
}

export interface SetValidLoungeByIdActionType {
  type: typeof SET_VALID_LOUNGE_BY_ID;
  payload: LoungeResource;
}

export type LoungeManagementAction =
  | loungeManagementSetLoungesActionType
  | setMyLoungeActionType
  | SetValidLoungeByIdActionType;

export type LoungeManagementThunk<T = void> = AppAction<
  LoungeManagementAction,
  T
>;
