import {
  MyBookings,
  AuthRegister,
  AuthUserProfile,
  RegisterQueryData,
  ChangePasswordQueryData,
} from './authTypes';
import { AppAction } from '../types';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const INIT_USER = 'INIT_USER';
export const SET_MFA_DATA = 'SET_MFA_SESSION';
export const SET_MY_PROFILE = 'SET_MY_PROFILE';
export const SET_MY_BOOKINGS = 'SET_MY_BOOKINGS';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const UPDATE_MY_PROFILE = 'UPDATE_MY_PROFILE';
export const FETCH_REGISTER_DATA = 'FETCH_REGISTER_DATA';
export const SET_EDIT_PASSWORD_DATA = 'SET_EDIT_PASSWORD_DATA';
export const SET_REGISTER_QUERY_DATA = 'SET_REGISTER_QUERY_DATA';
export const SET_RESET_PASSWORD_DATA = 'SET_RESET_PASSWORD_DATA';
export const SET_RESET_PASSWORD_EMAIL = 'SET_RESET_PASSWORD_EMAIL';
export const SET_PHONE_CONFIRMATION_DATA = 'SET_PHONE_CONFIRMATION_DATA';
export const SET_CHANGE_PASSWORD_QUERY_DATA = 'SET_CHANGE_PASSWORD_QUERY_DATA';

export interface clearUserDataActionType {
  type: typeof CLEAR_USER_DATA;
}

export interface fetchRegisterDataActionType {
  type: typeof FETCH_REGISTER_DATA;
  payload: AuthRegister;
}

export interface initUserActionType {
  type: typeof INIT_USER;
}

export interface loginActionType {
  type: typeof LOGIN;
  payload: AuthUserProfile;
}

export interface setMyProfileActionType {
  type: typeof SET_MY_PROFILE;
  payload: AuthUserProfile;
}

export interface setMyBookingsActionType {
  type: typeof SET_MY_BOOKINGS;
  payload: MyBookings[];
}

export interface logoutActionType {
  type: typeof LOGOUT;
}

export interface setRegisterQueryDataActionType {
  type: typeof SET_REGISTER_QUERY_DATA;
  payload: RegisterQueryData;
}

export interface setMfaDataActionType {
  type: typeof SET_MFA_DATA;
  payload: { session: string; email: string };
}

export interface setChangePasswordQueryDataActionType {
  type: typeof SET_CHANGE_PASSWORD_QUERY_DATA;
  payload: ChangePasswordQueryData;
}

export interface setResetPasswordEmailActionType {
  type: typeof SET_RESET_PASSWORD_EMAIL;
  payload: { email: string };
}

export interface setResetPasswordDataActionType {
  type: typeof SET_RESET_PASSWORD_DATA;
  payload: { email: string; code: string };
}

export interface UpdateMyProfileActionType {
  type: typeof UPDATE_MY_PROFILE;
  payload: AuthUserProfile;
}

export interface SetPhoneConfirmationActionType {
  type: typeof SET_PHONE_CONFIRMATION_DATA;
  payload: {
    needPhoneConfirmation?: boolean;
    phoneNumber?: string;
    email?: string;
    password?: string;
    refreshToken?: string;
  };
}

export interface setEditPasswordDataActionType {
  type: typeof SET_EDIT_PASSWORD_DATA;
  payload: {
    email?: string;
    oldPassword?: string;
    accessToken?: string;
  };
}

export type AuthAction =
  | loginActionType
  | logoutActionType
  | initUserActionType
  | setMfaDataActionType
  | setMyProfileActionType
  | setMyBookingsActionType
  | clearUserDataActionType
  | UpdateMyProfileActionType
  | fetchRegisterDataActionType
  | setResetPasswordDataActionType
  | setRegisterQueryDataActionType
  | setResetPasswordEmailActionType
  | SetPhoneConfirmationActionType
  | setChangePasswordQueryDataActionType
  | setEditPasswordDataActionType;

export type AuthThunkAction<T = void> = AppAction<AuthAction, T>;
