import {
  CompanyManagementType,
  CompanyManagementUsers,
  CompanyManagementCompany,
  CompanyManagementUsersPermission,
} from './companyManagementTypes';
import { AppAction } from '../types';

export const COMPANY_MANAGEMENT_SET_COMPANIES =
  'COMPANY_MANAGEMENT_SET_COMPANIES';

export const COMPANY_MANAGEMENT_SET_COMPANY_TYPES =
  'COMPANY_MANAGEMENT_SET_COMPANY_TYPES';

export const COMPANY_MANAGEMENT_ADD_COMPANY = 'COMPANY_MANAGEMENT_ADD_COMPANY';

export const COMPANY_MANAGEMENT_EDIT_COMPANY =
  'COMPANY_MANAGEMENT_EDIT_COMPANY';

export const COMPANY_MANAGEMENT_SET_COMPANY_USERS =
  'COMPANY_MANAGEMENT_SET_COMPANY_USERS';

export const COMPANY_MANAGEMENT_SET_USERS = 'COMPANY_MANAGEMENT_SET_USERS';

export const COMPANY_MANAGEMENT_SET_MY_COMPANY =
  'COMPANY_MANAGEMENT_SET_MY_COMPANY';

export interface companyManagementSetCompaniesActionType {
  type: typeof COMPANY_MANAGEMENT_SET_COMPANIES;
  payload: CompanyManagementCompany[];
}

export interface companyManagementAddCompanyActionType {
  type: typeof COMPANY_MANAGEMENT_ADD_COMPANY;
  payload: CompanyManagementCompany;
}

export interface companyManagementEditCompanyActionType {
  type: typeof COMPANY_MANAGEMENT_EDIT_COMPANY;
  payload: { id: number; company: CompanyManagementCompany };
}

export interface companyManagementSetCompanyTypesActionType {
  type: typeof COMPANY_MANAGEMENT_SET_COMPANY_TYPES;
  payload: CompanyManagementType[];
}

export interface companyManagementSetCompanyUsersActionType {
  type: typeof COMPANY_MANAGEMENT_SET_COMPANY_USERS;
  payload: CompanyManagementUsersPermission[];
}

export interface companyManagementSetUsersActionType {
  type: typeof COMPANY_MANAGEMENT_SET_USERS;
  payload: CompanyManagementUsers[];
}

export interface companyManagementSetMyCompanyActionType {
  type: typeof COMPANY_MANAGEMENT_SET_MY_COMPANY;
  payload: CompanyManagementCompany;
}

export type CompanyManagementAction =
  | companyManagementAddCompanyActionType
  | companyManagementEditCompanyActionType
  | companyManagementSetCompaniesActionType
  | companyManagementSetCompanyTypesActionType
  | companyManagementSetCompanyUsersActionType
  | companyManagementSetUsersActionType
  | companyManagementSetMyCompanyActionType;

export type CompanyManagementThunk<T = void> = AppAction<
  CompanyManagementAction,
  T
>;
