import {
  OPEN_OLD_MODAL,
  CLOSE_OLD_MODAL,
  ModalAction,
  STOP_LOADER,
  START_LOADER,
  SET_UNMOUNT_MODAL_CALLBACK,
} from './modalActionTypes';
import { ModalState } from './modalTypes';

const initialState: ModalState = {
  isLoaderActive: false,
};

export const MODAL_NAMESPACE = 'modal';

function modalReducer(state = initialState, action: ModalAction): ModalState {
  switch (action.type) {
    case OPEN_OLD_MODAL: {
      return {
        ...state,
        isOldModalOpen: true,
        component: action.payload.component,
        position: action.payload.position,
        isFullSizeOnMobile: action.payload.isFullSizeOnMobile,
        oldModalData: {
          callback: action.payload.callback,
          data: action.payload.data,
          cancelCallback: action.payload.cancelCallback,
        },
      };
    }
    case CLOSE_OLD_MODAL: {
      return { ...state, isOldModalOpen: false, component: undefined };
    }
    case START_LOADER: {
      return { ...state, isLoaderActive: true };
    }
    case STOP_LOADER: {
      return { ...state, isLoaderActive: false };
    }
    case SET_UNMOUNT_MODAL_CALLBACK: {
      return {
        ...state,
        onModalUnmountCallBack: action.payload,
      };
    }
    default:
      return state;
  }
}

export default modalReducer;
