import { AppAction } from '../types';
import { Event, EventCategory, EventSubCategory } from './eventTypes';

export const SET_EVENTS = 'SET_EVENTS';
export const SET_EVENT_BY_ID = 'SET_EVENT_BY_ID';
export const SET_EVENT_CATEGORIES = 'SET_EVENT_CATEGORIES';
export const SET_EVENT_TOTAL_PAGES = 'SET_EVENT_TOTAL_PAGES';
export const SET_EVENT_TOTAL_RESULTS = 'SET_EVENT_TOTAL_RESULTS';
export const SET_EVENT_SUB_CATEGORIES = 'SET_EVENT_SUB_CATEGORIES';

export interface SetEventsActionType {
  type: typeof SET_EVENTS;
  payload: Event[];
}
export interface SetEventTotalResultsActionType {
  type: typeof SET_EVENT_TOTAL_RESULTS;
  payload: number;
}

export interface SetEventTotalPagesActionType {
  type: typeof SET_EVENT_TOTAL_PAGES;
  payload: number;
}

export interface SetEventCategoriesActionType {
  type: typeof SET_EVENT_CATEGORIES;
  payload: EventCategory[];
}

export interface SetEventSubCategoriesActionType {
  type: typeof SET_EVENT_SUB_CATEGORIES;
  payload: EventSubCategory[];
}

export interface SetEventByIdActionType {
  type: typeof SET_EVENT_BY_ID;
  payload: Event;
}
export type EventAction =
  | SetEventsActionType
  | SetEventCategoriesActionType
  | SetEventSubCategoriesActionType
  | SetEventTotalResultsActionType
  | SetEventTotalPagesActionType
  | SetEventByIdActionType;

export type EventThunkAction<T = void> = AppAction<EventAction, T>;
