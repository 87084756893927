import {
  PropertiesManagementAction,
  PROPERTIES_MANAGEMENT_SET_LOCATION_TREE,
} from './propertyManagementActionTypes';
import { PropertiesManagementState } from './propertyManagementTypes';

const initialState: PropertiesManagementState = {};

export const PROPERTIES_MANAGEMENT_NAMESPACE =
  'PROPERTIES_MANAGEMENT_NAMESPACE';

function propertyManagementReducer(
  state = initialState,
  action: PropertiesManagementAction
): PropertiesManagementState {
  switch (action.type) {
    case PROPERTIES_MANAGEMENT_SET_LOCATION_TREE:
      return { ...state, location: action.location };

    default:
      return state;
  }
}

export default propertyManagementReducer;
