import {
  POLICY_MANAGEMENT_CLEAR_POLICY,
  POLICY_MANAGEMENT_SET_POLICY,
  PolicyManagementAction,
  SET_POLICY_FINE,
} from './policyManagementActionTypes';
import { PolicyStatusEnum } from './enums/policy-status.enum';
import { PolicyManagementState } from './policyManagementTypes';

const initialState: PolicyManagementState = {
  policy: {
    id: 0,
    name: '',
    description: '',
    eventDefault: false,
    bookingDefault: false,
    status: PolicyStatusEnum.ACTIVE,
    options: [],
  },
  fine: 0,
};

export const POLICY_MANAGEMENT_NAMESPACE = 'POLICY_MANAGEMENT_NAMESPACE';

function policyManagementReducer(
  state = initialState,
  action: PolicyManagementAction
): PolicyManagementState {
  switch (action.type) {
    case POLICY_MANAGEMENT_SET_POLICY:
      return { ...state, policy: action.payload };
    case POLICY_MANAGEMENT_CLEAR_POLICY:
      return {
        ...state,
        policy: {
          id: 0,
          name: '',
          description: '',
          eventDefault: false,
          bookingDefault: false,
          status: PolicyStatusEnum.ACTIVE,
          options: [],
        },
      };
    case SET_POLICY_FINE:
      return { ...state, fine: action.payload };
    default:
      return state;
  }
}

export default policyManagementReducer;
