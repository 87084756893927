import { BreadCrumbsState } from './breadCrumbsTypes';
import { BreadCrumbsAction, SET_BREAD_CRUMBS } from './breadCrumbsActionTypes';

const initialState: BreadCrumbsState = {};

export const BREAD_CRUMBS_NAMESPACE = 'BREAD_CRUMBS_NAMESPACE';

function breadCrumbsReducer(
  state = initialState,
  action: BreadCrumbsAction
): BreadCrumbsState {
  switch (action.type) {
    case SET_BREAD_CRUMBS:
      return { ...action.payload };
    default:
      return state;
  }
}

export default breadCrumbsReducer;
