import { AppAction } from '../types';
import { BreadCrumbsState } from './breadCrumbsTypes';

export const SET_BREAD_CRUMBS = 'SET_BREAD_CRUMBS';

export interface setBreadCrumbsActionType {
  type: typeof SET_BREAD_CRUMBS;
  payload: BreadCrumbsState;
}

export type BreadCrumbsAction = setBreadCrumbsActionType;

export type BreadCrumbsThunkAction<T = void> = AppAction<BreadCrumbsAction, T>;
