import {
  SlidesManagementAction,
  SLIDES_MANAGEMENT_SET_SLIDES,
  SLIDES_MANAGEMENT_SET_GENERAL_SLIDES,
} from './slidesManagementActionTypes';
import { SlidesManagementState } from './slidesManagementTypes';

const initialState: SlidesManagementState = {
  slides: [],
  isSlidesInit: false,
  generalSlides: [],
  isGeneralSlidesInit: false,
};

export const SLIDES_MANAGEMENT_NAMESPACE = 'SLIDES_MANAGEMENT_NAMESPACE';

function slidesManagementReducer(
  state = initialState,
  action: SlidesManagementAction
): SlidesManagementState {
  switch (action.type) {
    case SLIDES_MANAGEMENT_SET_SLIDES:
      return {
        ...state,
        slides: action.payload,
        isSlidesInit: true,
      };
    case SLIDES_MANAGEMENT_SET_GENERAL_SLIDES:
      return {
        ...state,
        generalSlides: action.payload,
        isGeneralSlidesInit: true,
      };
    default:
      return state;
  }
}

export default slidesManagementReducer;
