import {
  CompanyManagementAction,
  COMPANY_MANAGEMENT_SET_USERS,
  COMPANY_MANAGEMENT_EDIT_COMPANY,
  COMPANY_MANAGEMENT_SET_COMPANIES,
  COMPANY_MANAGEMENT_SET_COMPANY_TYPES,
  COMPANY_MANAGEMENT_SET_COMPANY_USERS,
  COMPANY_MANAGEMENT_SET_MY_COMPANY,
} from './companyManagementActionTypes';
import { CompanyManagementState } from './companyManagementTypes';

const initialState: CompanyManagementState = {
  companies: [],
  companyTypes: [],
  companyUsers: [],
  users: [],
  company: {},
};

export const COMPANY_MANAGEMENT_NAMESPACE = 'COMPANY_MANAGEMENT_NAMESPACE';

function companyManagementReducer(
  state = initialState,
  action: CompanyManagementAction
): CompanyManagementState {
  switch (action.type) {
    case COMPANY_MANAGEMENT_SET_COMPANIES:
      return { ...state, companies: action.payload };
    case COMPANY_MANAGEMENT_SET_COMPANY_TYPES:
      return { ...state, companyTypes: action.payload };
    case COMPANY_MANAGEMENT_SET_COMPANY_USERS:
      return { ...state, companyUsers: action.payload };
    case COMPANY_MANAGEMENT_SET_USERS:
      return { ...state, users: action.payload };
    case COMPANY_MANAGEMENT_EDIT_COMPANY:
      return {
        ...state,
        companies: state.companies.map((company) => {
          if (company.id === +action.payload.id) {
            return { ...company, ...action.payload.company };
          }
          return company;
        }),
      };
    case COMPANY_MANAGEMENT_SET_MY_COMPANY:
      return { ...state, company: action.payload };
    default:
      return state;
  }
}

export default companyManagementReducer;
