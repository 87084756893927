import { AppAction } from '../types';
import { ModalComponentEnum, ModalPosition } from './modalTypes';

export const OPEN_OLD_MODAL = 'OPEN_OLD_MODAL';
export const CLOSE_OLD_MODAL = 'CLOSE_MODAL';
export const STOP_LOADER = 'STOP_LOADER';
export const START_LOADER = 'START_LOADER';
export const SET_UNMOUNT_MODAL_CALLBACK = 'SET_UNMOUNT_MODAL_CALLBACK';

export interface openOldModalActionType {
  type: typeof OPEN_OLD_MODAL;
  payload: {
    component: ModalComponentEnum;
    position?: ModalPosition;
    isFullSizeOnMobile?: boolean;
    callback?: (data?: any) => void;
    data?: any;
    cancelCallback?: (data?: any) => void;
  };
}

export interface closeOldModalActionType {
  type: typeof CLOSE_OLD_MODAL;
}

export interface startLoaderActionType {
  type: typeof START_LOADER;
}

export interface stopLoaderActionType {
  type: typeof STOP_LOADER;
}

export interface setUnmountCallBackActionType {
  type: typeof SET_UNMOUNT_MODAL_CALLBACK;
  payload?: () => void;
}

export type ModalAction =
  | openOldModalActionType
  | closeOldModalActionType
  | startLoaderActionType
  | stopLoaderActionType
  | setUnmountCallBackActionType;

export type modalThunkAction<T = void> = AppAction<ModalAction, T>;
