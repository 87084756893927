import {
  SpacesManagementAction,
  SPACES_MANAGEMENT_SET_SPACES,
  SPACES_MANAGEMENT_SET_SPACES_BY_COMPANY,
} from './spacesManagementActionTypes';
import { SpacesManagementState } from './spacesManagementTypes';

const initialState: SpacesManagementState = {
  spaces: [],
  companySpaces: [],
};

export const OFFICES_MANAGEMENT_NAMESPACE = 'OFFICES_MANAGEMENT_NAMESPACE';

function spaceManagementReducer(
  state = initialState,
  action: SpacesManagementAction
): SpacesManagementState {
  switch (action.type) {
    case SPACES_MANAGEMENT_SET_SPACES:
      return { ...state, spaces: action.payload };
    case SPACES_MANAGEMENT_SET_SPACES_BY_COMPANY:
      return { ...state, companySpaces: action.payload };
    default:
      return state;
  }
}

export default spaceManagementReducer;
