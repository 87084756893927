import { AppAction } from '../types';
import { CreditCardType } from './creditCardsTypes';

export const SET_CREDIT_CARDS = 'SET_CREDIT_CARDS';
export const SET_SAVE_CREDIT_CARD = 'SET_SAVE_CREDIT_CARD';
export const SET_SELECTED_CREDIT_CARD = 'SET_SELECTED_CREDIT_CARD';

export interface SetCreditCardsActionType {
  type: typeof SET_CREDIT_CARDS;
  payload: CreditCardType[];
}

export interface SetSelectedCardActionType {
  type: typeof SET_SELECTED_CREDIT_CARD;
  payload: CreditCardType;
}

export interface SetSaveCreditCardActionType {
  type: typeof SET_SAVE_CREDIT_CARD;
  payload: boolean;
}

export type CreditCardsActions =
  | SetCreditCardsActionType
  | SetSelectedCardActionType
  | SetSaveCreditCardActionType;

export type CreditCardsThunks<T = void> = AppAction<CreditCardsActions, T>;
