import { AppAction } from '../types';
import {
  Credit,
  CreditInUse,
  CreditsUsage,
  UsageListItem,
  CreditsManagement,
  CreditManagementCreditType,
} from './creditsManagementTypes';

export const CREDITS_MANAGEMENT_REMOVE_CREDIT =
  'CREDITS_MANAGEMENT_REMOVE_CREDIT';
export const CREDITS_MANAGEMENT_SET_CREDIT_TYPES =
  'CREDITS_MANAGEMENT_SET_CREDIT_TYPES';
export const CREDITS_MANAGEMENT_REMOVE_CREDIT_BALANCE =
  'CREDITS_MANAGEMENT_REMOVE_CREDIT_BALANCE';
export const CREDITS_MANAGEMENT_SET_CREDITS_USAGE_LIST =
  'CREDITS_MANAGEMENT_SET_CREDITS_USAGE_LIST';
export const CREDITS_MANAGEMENT_SET_USER_USAGE_CREDITS =
  'CREDITS_MANAGEMENT_SET_USER_USAGE_CREDITS';
export const CREDITS_MANAGEMENT_REMOVE_PERSONAL_CREDIT =
  'CREDITS_MANAGEMENT_REMOVE_PERSONAL_CREDIT';
export const CREDITS_MANAGEMENT_SET_COMPANY_USAGE_CREDITS =
  'CREDITS_MANAGEMENT_SET_COMPANY_USAGE_CREDITS';
export const SET_USER_CREDITS = 'SET_USER_CREDITS';
export const CREDITS_MANAGEMENT_SET_USER_MANAGEMENT_CREDITS =
  'CREDITS_MANAGEMENT_SET_USER_MANAGEMENT_CREDITS';
export const CREDITS_MANAGEMENT_SET_ACTIVE_PERSONAL_CREDIT =
  'CREDITS_MANAGEMENT_SET_ACTIVE_PERSONAL_CREDIT';
export const CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_CREDITS =
  'CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_CREDITS';
export const CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_ACTIVE_CREDIT =
  'CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_ACTIVE_CREDIT';
export const SET_USER_CREDIT_IN_USE = 'SET_USER_CREDIT_IN_USE';
export const SET_PAYMENT_COMPANY_CREDITS = 'SET_USER_COMPANY_CREDITS';

export interface creditsManagementSetCompanyManagementActiveCreditActionType {
  type: typeof CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_ACTIVE_CREDIT;
  payload: CreditsManagement;
}

export interface creditsManagementSetCompanyManagementCreditsActionType {
  type: typeof CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_CREDITS;
  payload: CreditsManagement[];
}

export interface creditsManagementSetCompanyUsageCreditsActionType {
  type: typeof CREDITS_MANAGEMENT_SET_COMPANY_USAGE_CREDITS;
  payload: CreditsUsage[];
}

export interface creditsManagementRemoveCreditBalanceActionType {
  type: typeof CREDITS_MANAGEMENT_REMOVE_CREDIT_BALANCE;
  payload: number;
}

export interface creditsManagementRemoveCreditActionType {
  type: typeof CREDITS_MANAGEMENT_REMOVE_CREDIT;
  payload: number;
}

export interface creditsManagementSetCreditTypesActionType {
  type: typeof CREDITS_MANAGEMENT_SET_CREDIT_TYPES;
  payload: CreditManagementCreditType[];
}

export interface SetUserCreditsActionType {
  type: typeof SET_USER_CREDITS;
  payload: Credit[];
}

export interface SetCompanyCreditsActionType {
  type: typeof SET_PAYMENT_COMPANY_CREDITS;
  payload: CreditsUsage[];
}

export interface SetCreditInUseActionType {
  type: typeof SET_USER_CREDIT_IN_USE;
  payload: CreditInUse;
}

export interface SetCreditUsageListType {
  type: typeof CREDITS_MANAGEMENT_SET_CREDITS_USAGE_LIST;
  payload: UsageListItem[];
}

export interface creditsManagementSetUserManagementCreditsActionType {
  type: typeof CREDITS_MANAGEMENT_SET_USER_MANAGEMENT_CREDITS;
  payload: CreditsManagement[];
}

export interface creditsManagementSetActiveCreditPersonalActionType {
  type: typeof CREDITS_MANAGEMENT_SET_ACTIVE_PERSONAL_CREDIT;
  payload: CreditsManagement;
}

export interface creditsManagementSetUserUsageCreditsActionType {
  type: typeof CREDITS_MANAGEMENT_SET_USER_USAGE_CREDITS;
  payload: CreditsUsage[];
}

export interface creditsManagementRemovePersonalCreditActionType {
  type: typeof CREDITS_MANAGEMENT_REMOVE_PERSONAL_CREDIT;
  payload: number;
}

export type CreditsManagementAction =
  | creditsManagementSetCompanyManagementCreditsActionType
  | creditsManagementRemoveCreditBalanceActionType
  | creditsManagementSetCompanyUsageCreditsActionType
  | creditsManagementRemoveCreditActionType
  | creditsManagementSetCompanyManagementActiveCreditActionType
  | creditsManagementSetCreditTypesActionType
  | SetUserCreditsActionType
  | SetCompanyCreditsActionType
  | SetCreditInUseActionType
  | SetCreditUsageListType
  | creditsManagementSetUserManagementCreditsActionType
  | creditsManagementSetUserUsageCreditsActionType
  | creditsManagementSetActiveCreditPersonalActionType
  | creditsManagementRemovePersonalCreditActionType;

export type CreditsManagementThunk<T = void> = AppAction<
  CreditsManagementAction,
  T
>;
