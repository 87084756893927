import './loader.scss';

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader loader-window">
      <img
        className="fallback-logo"
        src="https://d1bl2vonzwz2m2.cloudfront.net/assets/logos/logo-ashtrom-host.png"
        alt="logo"
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
