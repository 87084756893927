import { AppAction } from '../types';
import { MyActivitiesState, MyActivityPayment } from './myActivitiesTypes';
import { MyActivityImpl } from '../../API/my-activities/implements/my-activity.impl';

export const SET_MY_ACTIVITIES = 'SET_MY_ACTIVITIES';
export const SET_SELECTED_ACTIVITY_PAYMENTS = 'SET_SELECTED_ACTIVITY_PAYMENTS';

export interface SetMyActivitiesActionType {
  type: typeof SET_MY_ACTIVITIES;
  payload: MyActivitiesState;
}

export interface SetSelectedActivityPaymentsActionType {
  type: typeof SET_SELECTED_ACTIVITY_PAYMENTS;
  payments: MyActivityPayment[];
}

export type MyActivitiesAction =
  | SetMyActivitiesActionType
  | SetSelectedActivityPaymentsActionType;

export type MyActivitiesThunkAction<T = void> = AppAction<
  MyActivitiesAction,
  T
>;
