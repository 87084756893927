import {
  Building,
  RoomResource,
  PropertiesManagementLocation,
} from './resourceManagementTypes';
import { AppAction } from '../types';

export const SET_LOCATION = 'SET_LOCATION';
export const SET_RESOURCE_BY_ID = 'SET_RESOURCE_BY_ID';
export const RESOURCE_MANAGEMENT_SET_RESOURCES =
  'RESOURCE_MANAGEMENT_SET_RESOURCES';
export const RESOURCE_MANAGEMENT_SET_BUILDINGS =
  'RESOURCE_MANAGEMENT_SET_BUILDINGS';
export const RESOURCE_MANAGEMENT_SET_SLOTS = 'RESOURCE_MANAGEMENT_SET_SLOTS';

export interface resourceManagementSetResourcesActionType {
  type: typeof RESOURCE_MANAGEMENT_SET_RESOURCES;
  payload: RoomResource[];
}

export interface resourceManagementSetBuildingsActionType {
  type: typeof RESOURCE_MANAGEMENT_SET_BUILDINGS;
  payload: Building[];
}

export interface resourceManagementSetSlotsActionType {
  type: typeof RESOURCE_MANAGEMENT_SET_SLOTS;
  payload: number[];
}

export interface setLocationActionType {
  type: typeof SET_LOCATION;
  payload: PropertiesManagementLocation;
}

export interface SetResourceByIdActionType {
  type: typeof SET_RESOURCE_BY_ID;
  payload: RoomResource;
}

export type ResourceManagementAction =
  | setLocationActionType
  | resourceManagementSetSlotsActionType
  | resourceManagementSetResourcesActionType
  | resourceManagementSetBuildingsActionType
  | SetResourceByIdActionType;

export type ResourceManagementThunk<T = void> = AppAction<
  ResourceManagementAction,
  T
>;
