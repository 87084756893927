import {
  AuthAction,
  CLEAR_USER_DATA,
  FETCH_REGISTER_DATA,
  INIT_USER,
  LOGIN,
  SET_CHANGE_PASSWORD_QUERY_DATA,
  SET_EDIT_PASSWORD_DATA,
  SET_MFA_DATA,
  SET_MY_BOOKINGS,
  SET_MY_PROFILE,
  SET_PHONE_CONFIRMATION_DATA,
  SET_REGISTER_QUERY_DATA,
  SET_RESET_PASSWORD_DATA,
  SET_RESET_PASSWORD_EMAIL,
  UPDATE_MY_PROFILE,
} from './authActionTypes';
import { AuthState } from './authTypes';

const initialState: AuthState = {
  isInit: false,
  mfaData: {
    session: '',
    email: '',
  },
  phoneConfirmation: {
    needPhoneConfirmation: false,
    phoneNumber: '',
    email: '',
    password: '',
    refreshToken: '',
  },
  editPasswordData: {
    email: '',
    oldPassword: '',
    accessToken: '',
  },
  myBookings: [],
  profile: {},
};

export const AUTH_NAMESPACE = 'AUTH_NAMESPACE';

function authReducer(state = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case FETCH_REGISTER_DATA: {
      return { ...state, register: action.payload };
    }
    case SET_PHONE_CONFIRMATION_DATA: {
      return {
        ...state,
        phoneConfirmation: { ...state.phoneConfirmation, ...action.payload },
      };
    }
    case SET_EDIT_PASSWORD_DATA: {
      return {
        ...state,
        editPasswordData: { ...state.editPasswordData, ...action.payload },
      };
    }
    case INIT_USER: {
      return { ...state, isInit: true };
    }
    case LOGIN: {
      return { ...state, profile: action.payload, isLogin: true };
    }
    case SET_MFA_DATA: {
      return {
        ...state,
        mfaData: {
          session: action.payload.session,
          email: action.payload.email,
        },
      };
    }
    case SET_MY_PROFILE: {
      return { ...state, profile: action.payload, isLogin: true };
    }
    case SET_MY_BOOKINGS: {
      return { ...state, myBookings: action.payload };
    }
    case SET_REGISTER_QUERY_DATA: {
      return {
        ...state,
        register: { ...state.register, queryData: action.payload },
      };
    }
    case SET_CHANGE_PASSWORD_QUERY_DATA: {
      return {
        ...state,
        changePassword: { ...state.changePassword, queryData: action.payload },
      };
    }
    case SET_RESET_PASSWORD_EMAIL: {
      return {
        ...state,
        resetPassword: { ...state.resetPassword, email: action.payload.email },
      };
    }
    case SET_RESET_PASSWORD_DATA: {
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          email: action.payload.email,
          code: action.payload.code,
        },
      };
    }
    case UPDATE_MY_PROFILE: {
      return { ...state, profile: action.payload };
    }
    case CLEAR_USER_DATA: {
      return {
        isInit: true,
        mfaData: {
          email: '',
          session: '',
        },
        phoneConfirmation: {
          needPhoneConfirmation: false,
          phoneNumber: '',
          password: '',
          email: '',
          refreshToken: '',
        },
        editPasswordData: {
          email: '',
          oldPassword: '',
          accessToken: '',
        },
        myBookings: [],
        profile: {},
      };
    }
    default:
      return state;
  }
}

export default authReducer;
