import {
  CreditsManagementAction,
  CREDITS_MANAGEMENT_REMOVE_CREDIT,
  CREDITS_MANAGEMENT_SET_CREDIT_TYPES,
  CREDITS_MANAGEMENT_REMOVE_CREDIT_BALANCE,
  CREDITS_MANAGEMENT_SET_CREDITS_USAGE_LIST,
  CREDITS_MANAGEMENT_SET_COMPANY_USAGE_CREDITS,
  CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_CREDITS,
  CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_ACTIVE_CREDIT,
} from './creditsManagementActionTypes';
import { CreditsManagementState } from './creditsManagementTypes';

const initialState: CreditsManagementState = {
  admin: {},
  company: {
    management: [],
    usage: [],
    creditTypes: [],
  },
  usageList: [],
};

export const CREDITS_MANAGEMENT_NAMESPACE = 'CREDITS_MANAGEMENT_NAMESPACE';

function creditsManagementReducer(
  state = initialState,
  action: CreditsManagementAction
): CreditsManagementState {
  switch (action.type) {
    case CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_ACTIVE_CREDIT:
      return {
        ...state,
        company: { ...state.company, activeManagementCredit: action.payload },
      };
    case CREDITS_MANAGEMENT_SET_COMPANY_MANAGEMENT_CREDITS:
      return {
        ...state,
        company: { ...state.company, management: action.payload },
      };
    case CREDITS_MANAGEMENT_SET_COMPANY_USAGE_CREDITS:
      return { ...state, company: { ...state.company, usage: action.payload } };
    case CREDITS_MANAGEMENT_SET_CREDIT_TYPES:
      return {
        ...state,
        company: { ...state.company, creditTypes: action.payload },
      };
    case CREDITS_MANAGEMENT_REMOVE_CREDIT:
      return {
        ...state,
        company: {
          ...state.company,
          management: state.company.management.filter(
            (credit) => credit.id !== action.payload
          ),
        },
      };
    case CREDITS_MANAGEMENT_REMOVE_CREDIT_BALANCE:
      return {
        ...state,
        company: {
          ...state.company,
          usage: state.company.usage.filter(
            (credit) => credit.id !== action.payload
          ),
        },
      };
    case CREDITS_MANAGEMENT_SET_CREDITS_USAGE_LIST:
      return {
        ...state,
        usageList: action.payload,
      };
    default:
      return state;
  }
}

export default creditsManagementReducer;
