import {
  SET_LOCATION,
  SET_RESOURCE_BY_ID,
  ResourceManagementAction,
  RESOURCE_MANAGEMENT_SET_SLOTS,
  RESOURCE_MANAGEMENT_SET_BUILDINGS,
  RESOURCE_MANAGEMENT_SET_RESOURCES,
} from './resourceManagementActionTypes';
import { ResourceManagementState } from './resourceManagementTypes';

const initialState: ResourceManagementState = {
  resources: [],
  resourceSlots: [],
  buildings: [],
  location: {
    id: +'',
    name: '',
    code: '',
    gymRegisterRequires: false,
    isGymAutoFillEnabled: false,
    isRepeatableGuestsEnabled: false,
    address: {
      id: +'',
      city: '',
      street: '',
      number: '',
      zip: '',
      country: '',
      wazeLink: '',
    },
    buildings: [],
    support: {
      email: '',
      phone: '',
    },
    extraInformation: {
      rentMapLink: '',
      restaurantLink: '',
      parkingLink: '',
    },
  },
};

export const ROOM_MANAGEMENT_NAMESPACE = 'ROOM_MANAGEMENT_NAMESPACE';

function roomManagementReducer(
  state = initialState,
  action: ResourceManagementAction
): ResourceManagementState {
  switch (action.type) {
    case RESOURCE_MANAGEMENT_SET_SLOTS:
      return { ...state, resourceSlots: action.payload };
    case RESOURCE_MANAGEMENT_SET_RESOURCES:
      return { ...state, resources: action.payload, resource: undefined };
    case RESOURCE_MANAGEMENT_SET_BUILDINGS:
      return { ...state, buildings: action.payload };
    case SET_LOCATION:
      return { ...state, location: action.payload };
    case SET_RESOURCE_BY_ID:
      return { ...state, resource: action.payload };
    default:
      return state;
  }
}

export default roomManagementReducer;
