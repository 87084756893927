import { combineReducers } from 'redux';
import creditCardsReducer, {
  CREDIT_CARDS_NAMESPACE,
} from '../creditCards/creditCardsReducer';
import breadCrumbsReducer, {
  BREAD_CRUMBS_NAMESPACE,
} from '../BreadCrumbs/breadCrumbsReducer';
import navbar from '../../redux-old/navbar';
import layout from '../../redux-old/layout';
import loungeManagementReducer, {
  LOUNGE_MANAGEMENT_NAMESPACE,
} from '../lounges/loungesManagementReducer';
import myActivitiesReducer, {
  MY_ACTIVITIES_NAMESPACE,
} from '../my-activities/myActivitiesReducer';
import supportReducer, {
  SUPPORT_NAMESPACE,
} from '../support-management/SupportReducer';
import todo from '../../views/apps/todo/store';
import users from '../../views/apps/user/store';
import { LOGOUT } from '../Auth/authActionTypes';
import cronJobsReducer, {
  CRON_JOBS_NAMESPACE,
} from '../cron-jobs-management/CronJobsReducer';
import email from '../../views/apps/email/store';
import roomManagementReducer, {
  ROOM_MANAGEMENT_NAMESPACE,
} from '../RoomManagement/resourceManagementReducer';
import invoice from '../../views/apps/invoice/store';
import guestsManagementReducer, {
  GUESTS_MANAGEMENT_NAMESPACE,
} from '../guests-management/guestsManagementReducer';
import spaceManagementReducer, {
  OFFICES_MANAGEMENT_NAMESPACE,
} from '../SpacesManagement/spacesManagementReducer';
import ordersManagementReducer, {
  ORDERS_MANAGEMENT_NAMESPACE,
} from '../OrdersManagement/ordersManagementReducer';
import slidesManagementReducer, {
  SLIDES_MANAGEMENT_NAMESPACE,
} from '../SlidesManagement/slidesManagementReducer';
import policyManagementReducer, {
  POLICY_MANAGEMENT_NAMESPACE,
} from '../PolicyManagement/policyManagementReducer';
import companyManagementReducer, {
  COMPANY_MANAGEMENT_NAMESPACE,
} from '../CompanyManagement/companyManagementReducer';
import calendar from '../../views/apps/calendar/store';
import creditsManagementReducer, {
  CREDITS_MANAGEMENT_NAMESPACE,
} from '../CreditsManagement/creditsManagementReducer';
import ecommerce from '../../views/apps/ecommerce/store';
import propertyManagementReducer, {
  PROPERTIES_MANAGEMENT_NAMESPACE,
} from '../PropertiesManagement/propertyManagementReducer';
import authReducer, { AUTH_NAMESPACE } from '../Auth/authReducer';
import subscriptionsManagementReducer, {
  SUBSCRIPTIONS_MANAGEMENT_NAMESPACE,
} from '../SubscriptionsManagement/subscriptionsManagementReducer';
import permissions from '../../views/apps/roles-permissions/store';
import modalReducer, { MODAL_NAMESPACE } from '../modal/modalReducer';
import eventReducer, { EVENT_NAMESPACE } from '../events/eventReducer';
import gymReducer, { GYM_NAMESPACE } from '../gym-management/GymReducer';
import faqReducer, { FAQ_NAMESPACE } from '../faq-management/FaqReducer';
import creditsReducer, { CREDITS_NAMESPACE } from '../credits/creditsReducer';

const appReducer = combineReducers({
  [GYM_NAMESPACE]: gymReducer,
  [FAQ_NAMESPACE]: faqReducer,
  [AUTH_NAMESPACE]: authReducer,
  [MODAL_NAMESPACE]: modalReducer,
  [EVENT_NAMESPACE]: eventReducer,
  [CREDITS_NAMESPACE]: creditsReducer,
  [SUPPORT_NAMESPACE]: supportReducer,
  [CRON_JOBS_NAMESPACE]: cronJobsReducer,
  [CREDIT_CARDS_NAMESPACE]: creditCardsReducer,
  [BREAD_CRUMBS_NAMESPACE]: breadCrumbsReducer,
  [MY_ACTIVITIES_NAMESPACE]: myActivitiesReducer,
  [ROOM_MANAGEMENT_NAMESPACE]: roomManagementReducer,
  [SLIDES_MANAGEMENT_NAMESPACE]: slidesManagementReducer,
  [LOUNGE_MANAGEMENT_NAMESPACE]: loungeManagementReducer,
  [GUESTS_MANAGEMENT_NAMESPACE]: guestsManagementReducer,
  [OFFICES_MANAGEMENT_NAMESPACE]: spaceManagementReducer,
  [ORDERS_MANAGEMENT_NAMESPACE]: ordersManagementReducer,
  [POLICY_MANAGEMENT_NAMESPACE]: policyManagementReducer,
  [COMPANY_MANAGEMENT_NAMESPACE]: companyManagementReducer,
  [CREDITS_MANAGEMENT_NAMESPACE]: creditsManagementReducer,
  [PROPERTIES_MANAGEMENT_NAMESPACE]: propertyManagementReducer,
  [SUBSCRIPTIONS_MANAGEMENT_NAMESPACE]: subscriptionsManagementReducer,

  //Template redux-old
  todo,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  permissions,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    const reducer = appReducer(undefined, action);
    return {
      ...reducer,
      layout: {
        ...reducer.layout,
        isRTL: localStorage.getItem('i18nextLng') === 'he',
      },
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
