import {
  GymActions,
  SET_GYM_EVENTS,
  SET_MY_GYM_ROOM,
  SET_ALL_GYM_ROOMS,
} from './GymActionTypes';
import { GymState } from './GymTypes';

const initialState: GymState = {
  gymEvents: [],
  gymRooms: [],
};

export const GYM_NAMESPACE = 'GYM_NAMESPACE';

function gymReducer(state = initialState, action: GymActions): GymState {
  switch (action.type) {
    case SET_GYM_EVENTS:
      return { ...state, gymEvents: action.payload };
    case SET_MY_GYM_ROOM:
      return { ...state, myGymRoom: action.payload };
    case SET_ALL_GYM_ROOMS:
      return { ...state, gymRooms: action.payload };
    default:
      return state;
  }
}

export default gymReducer;
