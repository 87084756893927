import { AppAction } from '../types';
import { GymResource } from './GymTypes';
import { Event } from '../events/eventTypes';

export const SET_GYM_EVENTS = 'SET_GYM_EVENTS';
export const SET_MY_GYM_ROOM = 'SET_MY_GYM_ROOM';
export const SET_ALL_GYM_ROOMS = 'SET_ALL_GYM_ROOMS';

export interface SetGymEventsActionType {
  type: typeof SET_GYM_EVENTS;
  payload: Event[];
}

export interface SetMyGymRoomActionType {
  type: typeof SET_MY_GYM_ROOM;
  payload: GymResource;
}

export interface SetAllGymRoomsActionType {
  type: typeof SET_ALL_GYM_ROOMS;
  payload: GymResource[];
}

export type GymActions =
  | SetGymEventsActionType
  | SetMyGymRoomActionType
  | SetAllGymRoomsActionType;

export type GymThunkAction<T = void> = AppAction<GymActions, T>;
