import { AppAction } from '../types';
import { SpacesManagementSpace } from './spacesManagementTypes';

export const SPACES_MANAGEMENT_SET_SPACES_BY_COMPANY =
  'SPACES_MANAGEMENT_SET_SPACES_BY_COMPANY';
export const SPACES_MANAGEMENT_ADD_SPACE = 'SPACES_MANAGEMENT_ADD_SPACE';
export const SPACES_MANAGEMENT_SET_SPACES = 'SPACES_MANAGEMENT_SET_SPACES';
export const SPACES_MANAGEMENT_EDIT_SPACE = 'SPACES_MANAGEMENT_EDIT_SPACE';
export const SPACES_MANAGEMENT_DELETE_SPACE = 'SPACES_MANAGEMENT_DELETE_SPACE';

export interface spacesManagementSetSpacesActionType {
  type: typeof SPACES_MANAGEMENT_SET_SPACES;
  payload: SpacesManagementSpace[];
}

export interface spacesManagementSetSpacesByCompanyActionType {
  type: typeof SPACES_MANAGEMENT_SET_SPACES_BY_COMPANY;
  payload: SpacesManagementSpace[];
}

export interface spacesManagementRemoveSpaceActionType {
  type: typeof SPACES_MANAGEMENT_DELETE_SPACE;
  spaceId: number;
}

export interface SpacesManagementAddSpaceActionType {
  type: typeof SPACES_MANAGEMENT_ADD_SPACE;
  payload: SpacesManagementSpace;
}

export interface SpacesManagementEditSpaceActionType {
  type: typeof SPACES_MANAGEMENT_EDIT_SPACE;
  payload: { id: number; space: SpacesManagementSpace };
}

export type SpacesManagementAction =
  | spacesManagementSetSpacesActionType
  | spacesManagementRemoveSpaceActionType
  | SpacesManagementAddSpaceActionType
  | SpacesManagementEditSpaceActionType
  | spacesManagementSetSpacesByCompanyActionType;

export type SpacesManagementThunk<T = void> = AppAction<
  SpacesManagementAction,
  T
>;
