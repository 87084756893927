import { AppAction } from '../types';
import { CancellationPolicy } from './policyManagementTypes';

export const POLICY_MANAGEMENT_SET_POLICY = 'POLICY_MANAGEMENT_SET_POLICY';
export const POLICY_MANAGEMENT_CLEAR_POLICY = 'POLICY_MANAGEMENT_CLEAR_POLICY';
export const SET_POLICY_FINE = 'SET_POLICY_FINE';

export interface policyManagementSetPolicyActionType {
  type: typeof POLICY_MANAGEMENT_SET_POLICY;
  payload: CancellationPolicy;
}

export interface policyManagementClearPolicyActionType {
  type: typeof POLICY_MANAGEMENT_CLEAR_POLICY;
}

export interface policyManagementSetPolicyFineActionType {
  type: typeof SET_POLICY_FINE;
  payload: number;
}

export type PolicyManagementAction =
  | policyManagementSetPolicyActionType
  | policyManagementClearPolicyActionType
  | policyManagementSetPolicyFineActionType;

export type PolicyManagementThunk<T = void> = AppAction<
  PolicyManagementAction,
  T
>;
