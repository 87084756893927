import {
  SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS,
  SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS_MAP,
  SUBSCRIPTIONS_MANAGEMENT_SET_USER_SUBSCRIPTIONS,
  SubscriptionsManagementAction,
} from './subscriptionsManagementActionTypes';
import { SubscriptionsManagementState } from './subscriptionsManagementTypes';
import { MembershipDates } from '../../utility/functions/setEventPriceFromMembership';

const initialState: SubscriptionsManagementState = {
  subscriptions: [],
  subscriptionsMap: new Map<number, MembershipDates[]>(),
  user: {
    subscriptions: [],
    isInit: false,
  },
};

export const SUBSCRIPTIONS_MANAGEMENT_NAMESPACE =
  'SUBSCRIPTIONS_MANAGEMENT_NAMESPACE';

function subscriptionsManagementReducer(
  state = initialState,
  action: SubscriptionsManagementAction
): SubscriptionsManagementState {
  switch (action.type) {
    case SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };
    case SUBSCRIPTIONS_MANAGEMENT_SET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        user: { ...state, isInit: true, subscriptions: action.payload },
      };
    case SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS_MAP:
      return {
        ...state,
        subscriptionsMap: action.payload,
      };
    default:
      return state;
  }
}

export default subscriptionsManagementReducer;
