import {
  SlidesManagementSlide,
  SlidesManagementGeneralSlide,
} from './slidesManagementTypes';
import { AppAction } from '../types';

export const SLIDES_MANAGEMENT_SET_GENERAL_SLIDES =
  'SLIDES_MANAGEMENT_SET_GENERAL_SLIDES';

export const SLIDES_MANAGEMENT_SET_SLIDES = 'SLIDES_MANAGEMENT_SET_SLIDES';

export interface slidesManagementSetSlidesActionType {
  type: typeof SLIDES_MANAGEMENT_SET_SLIDES;
  payload: SlidesManagementSlide[];
}

export interface slidesManagementSetGeneralSlidesActionType {
  type: typeof SLIDES_MANAGEMENT_SET_GENERAL_SLIDES;
  payload: SlidesManagementGeneralSlide[];
}

export type SlidesManagementAction =
  | slidesManagementSetSlidesActionType
  | slidesManagementSetGeneralSlidesActionType;

export type SlidesManagementThunkAction<T = void> = AppAction<
  SlidesManagementAction,
  T
>;
