import { AppAction } from '../types';
import { FaqCategory } from './FaqTypes';

export const SET_FAQ_CATEGORIES = 'SET_FAQ_CATEGORIES';

export interface SetFaqCategoriesActionType {
  type: typeof SET_FAQ_CATEGORIES;
  payload: FaqCategory[];
}

export type FaqActions = SetFaqCategoriesActionType;

export type FaqThunkAction<T = void> = AppAction<FaqActions, T>;
