import { CronJobsState } from './CronJobsTypes';
import { CronJobsActionsTypes } from './CronJobsActionTypes';

const initialState: CronJobsState = {
  cronJobs: {},
};

export const CRON_JOBS_NAMESPACE = 'CRON_JOBS_NAMESPACE';

function cronJobsReducer(
  state = initialState,
  action: CronJobsActionsTypes
): CronJobsState {
  switch (action.type) {
    default:
      return state;
  }
}

export default cronJobsReducer;
