import { SupportState } from './FaqTypes';
import { FaqActions, SET_FAQ_CATEGORIES } from './FaqActionTypes';

const initialState: SupportState = {
  faqCategories: [],
};

export const FAQ_NAMESPACE = 'FAQ_NAMESPACE';

function faqReducer(state = initialState, action: FaqActions): SupportState {
  switch (action.type) {
    case SET_FAQ_CATEGORIES:
      return { ...state, faqCategories: action.payload };
    default:
      return state;
  }
}

export default faqReducer;
