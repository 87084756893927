import React, { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import Loader from '../components/modals/Loader/Loader';
import { AbilityContext } from '../utility/context/Can';
import ability from '../configs/acl/ability';
import { ThemeContext } from '../utility/context/ThemeColors';
import { ToastContainer } from 'react-toastify';

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <>
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              {children}
              <ToastContainer newestOnTop />
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </Provider>
    </>
  );
};

export default AppProvider;
