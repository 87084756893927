import {
  SupportActions,
  SET_SUPPORT_IMAGE,
  SET_SUPPORT_CATEGORIES,
  SET_SUPPORT_SUB_CATEGORIES,
  REMOVE_SUPPORT_IMAGE,
} from './SupportActionTypes';
import { SupportState } from './SupportTypes';

const initialState: SupportState = {
  supportCategories: [],
  supportSubCategories: [],
};

export const SUPPORT_NAMESPACE = 'SUPPORT_NAMESPACE';

function supportReducer(
  state = initialState,
  action: SupportActions
): SupportState {
  switch (action.type) {
    case SET_SUPPORT_CATEGORIES:
      return { ...state, supportCategories: action.payload };
    case SET_SUPPORT_SUB_CATEGORIES:
      return { ...state, supportSubCategories: action.payload };
    case REMOVE_SUPPORT_IMAGE:
      if (!action.index) {
        return { ...state, supportImage: undefined };
      }
      return {
        ...state,
        supportImage: state.supportImage?.filter(
          (item, index) => index !== action.index
        ),
      };
    case SET_SUPPORT_IMAGE:
      const newImages =
        state.supportImage && action.payload
          ? [...state.supportImage, ...action.payload]
          : action.payload;
      return {
        ...state,
        supportImage: newImages,
      };
    default:
      return state;
  }
}

export default supportReducer;
