import {
  SET_CREDIT_CARDS,
  CreditCardsActions,
  SET_SAVE_CREDIT_CARD,
  SET_SELECTED_CREDIT_CARD,
} from './creditCardsActionTypes';
import { CreditCardsState } from './creditCardsTypes';

const initialState: CreditCardsState = {
  creditCards: [],
  saveCreditCard: false,
};

export const CREDIT_CARDS_NAMESPACE = 'CREDIT_CARDS_NAMESPACE';

function creditCardsReducer(
  state = initialState,
  action: CreditCardsActions
): CreditCardsState {
  switch (action.type) {
    case SET_CREDIT_CARDS:
      return {
        ...state,
        creditCards: action.payload,
        selectedCreditCard: undefined,
      };

    case SET_SELECTED_CREDIT_CARD:
      return { ...state, selectedCreditCard: action.payload };

    case SET_SAVE_CREDIT_CARD:
      return { ...state, saveCreditCard: action.payload };
    default:
      return state;
  }
}

export default creditCardsReducer;
