import { AppAction } from '../types';
import { Booking, Misc } from './ordersManagementTypes';

export const ORDERS_MANAGEMENT_SET_COMPANY_MISC_ORDER =
  'ORDERS_MANAGEMENT_SET_COMPANY_MISC_ORDER';
export const ORDERS_MANAGEMENT_SET_COMPANY_BOOKING_ORDER =
  'ORDERS_MANAGEMENT_SET_COMPANY_BOOKING_ORDER';
export const ORDER_MANAGEMENT_SET_SLOTS = 'ORDER_MANAGEMENT_SET_SLOTS';

export interface orderManagementSetSlotsActionType {
  type: typeof ORDER_MANAGEMENT_SET_SLOTS;
  payload: number[];
}

export interface ordersManagementSetCompanyBookingOrderActionType {
  type: typeof ORDERS_MANAGEMENT_SET_COMPANY_BOOKING_ORDER;
  payload: Booking[];
}

export interface ordersManagementSetCompanyMiscOrderActionType {
  type: typeof ORDERS_MANAGEMENT_SET_COMPANY_MISC_ORDER;
  payload: Misc[];
}

export type OrdersManagementAction =
  | orderManagementSetSlotsActionType
  | ordersManagementSetCompanyMiscOrderActionType
  | ordersManagementSetCompanyBookingOrderActionType;

export type OrdersManagementThunk<T = void> = AppAction<
  OrdersManagementAction,
  T
>;
