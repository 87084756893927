import {
  CreditsAction,
  SET_CREDITS,
  SET_CREDIT_IN_USE,
} from './creditsActionTypes';
import { CreditsState } from './creditsTypes';

const initialState: CreditsState = {
  credits: [],
  creditInUse: {},
};

export const CREDITS_NAMESPACE = 'CREDITS_NAMESPACE';

function creditsReducer(
  state = initialState,
  action: CreditsAction
): CreditsState {
  switch (action.type) {
    case SET_CREDITS:
      return { ...state, credits: action.payload };
    case SET_CREDIT_IN_USE:
      return { ...state, creditInUse: action.payload };

    default:
      return state;
  }
}

export default creditsReducer;
