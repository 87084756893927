import { AppAction } from '../types';
import {
  UserMemberships,
  SubscriptionManagement,
} from './subscriptionsManagementTypes';
import { MembershipDates } from '../../utility/functions/setEventPriceFromMembership';

export const SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS =
  'SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS';

export const SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS_MAP =
  'SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS_MAP';

export const SUBSCRIPTIONS_MANAGEMENT_SET_USER_SUBSCRIPTIONS =
  'SUBSCRIPTIONS_MANAGEMENT_SET_USER_SUBSCRIPTIONS';

export interface subscriptionsManagementSetSubscriptionsActionType {
  type: typeof SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS;
  payload: SubscriptionManagement[];
}

export interface subscriptionsManagementSetSubscriptionsMapActionType {
  type: typeof SUBSCRIPTIONS_MANAGEMENT_SET_SUBSCRIPTIONS_MAP;
  payload: Map<number, MembershipDates[]>;
}

export interface subscriptionsManagementSetUserSubscriptionsActionType {
  type: typeof SUBSCRIPTIONS_MANAGEMENT_SET_USER_SUBSCRIPTIONS;
  payload: UserMemberships[];
}

export type SubscriptionsManagementAction =
  | subscriptionsManagementSetSubscriptionsActionType
  | subscriptionsManagementSetSubscriptionsMapActionType
  | subscriptionsManagementSetUserSubscriptionsActionType;

export type SubscriptionsManagementThunk<T = void> = AppAction<
  SubscriptionsManagementAction,
  T
>;
