import {
  OrdersManagementAction,
  ORDERS_MANAGEMENT_SET_COMPANY_MISC_ORDER,
  ORDERS_MANAGEMENT_SET_COMPANY_BOOKING_ORDER,
} from './ordersManagementActionTypes';
import { OrdersManagementState } from './ordersManagementTypes';

const initialState: OrdersManagementState = {
  company: {
    booking: [],
    misc: [],
  },
};

export const ORDERS_MANAGEMENT_NAMESPACE = 'ORDERS_MANAGEMENT_NAMESPACE';

function ordersManagementReducer(
  state = initialState,
  action: OrdersManagementAction
): OrdersManagementState {
  switch (action.type) {
    case ORDERS_MANAGEMENT_SET_COMPANY_BOOKING_ORDER:
      return {
        ...state,
        company: { ...state.company, booking: action.payload },
      };
    case ORDERS_MANAGEMENT_SET_COMPANY_MISC_ORDER:
      return {
        ...state,
        company: { ...state.company, misc: action.payload },
      };
    default:
      return state;
  }
}

export default ordersManagementReducer;
